@import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  font-family: "Archivo", sans-serif;
}

.header img {
  height: 100px;
}
.herosection {
  margin-top: 50px;
}
.herosection h1 {
  font-size: 50px;
  font-weight: 800;
  color: #2b3366;
}
.herosection h2 {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 22px;
}
.herosection b {
  font-weight: 800;
}
.herosection img {
  box-shadow: 15px 15px 0 -6px #2b3366;
}
.herosection small {
  opacity: 0.7;
  font-size: 10px;
  padding-top: 20px;
  display: inline-block;
}

.herosection .disclimer {
  color: rgba(0, 0, 0, 0.503);
  font-size: 11px;
  padding-top: 80px;
}
.btn {
  background-color: #2b3366;
  color: #fff;
  border: 2px solid #2b3366 !important;
}
.btn:hover {
  background-color: #2b3366 !important;
}
.btn:active,
.btn:focus {
  background-color: #fff !important;
  outline: 0px !important;
  -webkit-appearance: none;
  box-shadow: none !important;
  border: 2px solid #2b3366 !important;
  color: #2b3366 !important;
}

@media (max-width: 800px) {
  .header img {
    height: 80px;
    width: auto;
  }
  .herosection img {
    margin-top: 20px;
  }
  .header .img-fluid {
    max-width: none;
  }
  .herosection .disclimer {
    padding-top: 40px;
  }
}

.model_pageload .good_news {
  font-size: 20px;
  font-weight: 600;
}
.model_pageload .phase_two {
  font-size: 30px;
  font-weight: bold;
}
.model_pageload img {
  margin-top: 30px;
  margin-bottom: 30px;
  height: 150px;
}
.model_pageload h1 {
  font-weight: 800;
}
